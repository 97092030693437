import { useCallback, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { m } from 'framer-motion';
import { Details, Flex, HistoryList, SelectOption } from '@components';
import { useOrders } from '@services/poms';
import { changePlacesVariants } from '@utils/motions';
import { useCustomer } from '@services/shopify';
import { getShopifyIdFromGID } from '@utils/index';
import { useIsomorphicLayoutEffect } from '@utils/hooks';
import styles from './History.module.scss';

const headerAndAccountTabsHeight = 144;

const History = ({ forwardedRef }) => {
	const { data: customer } = useCustomer();
	const userId = getShopifyIdFromGID(customer?.id);
	const { query } = useRouter();
	const [page, setPage] = useState(1);
	const [range, setRange] = useState(null);
	const { data: orderData, isLoading } = useOrders({ userId, page, range });
	const [pagesLength, setPagesLength] = useState(orderData?.pagination.pages ?? 1);
	const [orderNumber, setOrderNumber] = useState(orderData?.orders[0].order_number ?? '');
	const [inView, setInView] = useState(true);
	const [detailsHeight, setDetailsHeight] = useState(0);
	const [prevClickedElement, setPrevClickedElement] = useState<EventTarget & HTMLDivElement>(null);

	const parentRef = useRef<HTMLDivElement>(null);
	const detailsRef = useRef<HTMLDivElement>(null);
	const historyRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!isLoading && inView && forwardedRef.current && historyRef.current) {
			const parentHeight = forwardedRef.current.clientHeight;
			const childHeight = historyRef.current.clientHeight;
			if (parentHeight !== childHeight) {
				forwardedRef.current.style.height = childHeight + headerAndAccountTabsHeight + 'px';
			}
		}
	}, [forwardedRef, isLoading, historyRef, inView, prevClickedElement]);

	const handleItemClick = (id: string, el?: EventTarget & HTMLDivElement) => {
		setOrderNumber(id);
		setInView(!inView);

		// Scroll Positioning and Views
		el && setPrevClickedElement(el);
		if (prevClickedElement && !inView) prevClickedElement.scrollIntoView({ block: 'center' });
		if (inView) parentRef.current.scrollIntoView(false);
	};

	const handleSelect = useCallback((selection: SelectOption) => {
		setRange(selection.value);
	}, []);

	const handlePagination = useCallback(selection => setPage(selection), []);

	useEffect(() => {
		if (query?.orderNumber) {
			setOrderNumber(query.orderNumber as string);
			setInView(false);
		}
	}, [query]);

	useEffect(() => {
		if (!orderData) return;
		if (!pagesLength || pagesLength !== orderData.pagination.pages) return setPagesLength(orderData.pagination.pages);
	}, [orderData, pagesLength, setPagesLength]);

	useIsomorphicLayoutEffect(() => {
		if (!inView && forwardedRef.current) {
			forwardedRef.current.style.height = detailsHeight + headerAndAccountTabsHeight + 'px';
		}
	}, [forwardedRef, inView, detailsHeight]);

	return (
		<Flex className={styles['history']} ref={parentRef}>
			<m.div
				style={{ position: 'absolute' }}
				initial={'visible'}
				animate={inView ? 'visible' : 'hidden'}
				variants={changePlacesVariants}
				className={styles['history__animation-wrapper']}
				ref={historyRef}
			>
				<HistoryList
					containerClass={styles['history__list-container']}
					handleItemClick={handleItemClick}
					handlePagination={handlePagination}
					handleSelect={handleSelect}
					isLoading={isLoading}
					orderData={orderData}
					pagesLength={pagesLength}
				/>
			</m.div>
			<m.div
				style={{ position: 'absolute' }}
				initial={'hidden'}
				animate={inView ? 'hidden' : 'visible'}
				variants={changePlacesVariants}
				className={styles['details__animation-wrapper']}
			>
				{orderData ? (
					<Details
						containerClass={styles['details__list-container']}
						orderNumber={orderNumber}
						handleItemClick={handleItemClick}
						setDetailsHeight={setDetailsHeight}
						ref={detailsRef}
					/>
				) : null}
			</m.div>
		</Flex>
	);
};

export default History;
